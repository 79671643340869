import { DataGrid } from "@material-ui/data-grid";
import { useHistory } from "react-router-dom";

const columns = [
  { field: "name", headerName: "Connector", width: 200 },
  { field: "domains", headerName: "Domains", width: 200 },
  { field: "status", headerName: "Status", width: 200 },
];

export default function ConnectorsTable({ connectors }) {
  const history = useHistory();

  const handleRowClick = (param, event) => {
    history.push(`/admin/edit-sso/${param.id}`);
  };

  return (
    <div style={{ height: 400, width: "100%", margin: "40px auto" }}>
      <DataGrid
        rows={connectors}
        columns={columns}
        pageSize={10}
        rowHeight={35}
        onRowClick={handleRowClick}
        getRowId={(row) => row.sso_id}
      />
    </div>
  );
}
