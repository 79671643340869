import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export default function CustomSelect({
  id,
  name,
  label,
  value,
  options,
  handleChange,
}) {
  return (
    <FormControl margin="dense">
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        name={name}
        labelId={name}
        id={id}
        value={value}
        onChange={handleChange}
      >
        {Object.keys(options).map((key) => (
          <MenuItem key={key} value={key}>
            {options[key]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
