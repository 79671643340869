import { useQuery, useQueryClient, useMutation } from "react-query";

import * as Api from "../api/api";

export function useGetDomains(sso_id) {
  return useQuery(["domains", { sso_id }], Api.getDomains);
}

export function useDomainMutation(sso_id, onError) {
  const cache = useQueryClient();

  function invalidateDomainQueries() {
    cache.invalidateQueries(["domains", { sso_id }]);
    cache.invalidateQueries(["connector", { id: sso_id }]);
    cache.invalidateQueries("connectors");
  }

  const { mutate: addDomain } = useMutation(Api.addDomain, {
    onSuccess: invalidateDomainQueries,
    onError,
  });

  const { mutate: updateDomain } = useMutation(Api.updateDomain, {
    onSuccess: invalidateDomainQueries,
    onError,
  });

  const { mutate: deleteDomain } = useMutation(Api.deleteDomain, {
    onSuccess: invalidateDomainQueries,
    onError,
  });

  return { addDomain, updateDomain, deleteDomain };
}
