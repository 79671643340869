import { useState } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";

import CustomSelect from "./CustomSelect";
import CustomInput from "./CustomInput";
import Loader from "./Loader";
import useModal from "../hooks/useModal";
import { isValidConnectorName } from "../helpers";
import {
  SSO_TYPE_OPTIONS,
  SSO_STATUS_OPTIONS,
  errorMessages,
} from "../constants";

const ssoRequiredFields = [
  "sso_type",
  "name",
  "identifier",
  "connector",
  "scope",
  "status",
];

export default function ConnectorForm({
  ssoData,
  onFormSubmit,
  isLoading,
  apiError,
  setApiError,
}) {
  const { showModal, hideModal, RenderModal } = useModal();
  const [sso, setSSO] = useState(ssoData);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSSO({ ...sso, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setApiError("");
    if (validateSSO(sso)) {
      setError("");
      showModal();
    }
  };

  const handleUpdateSSO = () => {
    hideModal();
    onFormSubmit(sso);
  };

  const validateSSO = (sso) => {
    let formErrors = 0;
    ssoRequiredFields.forEach((field) => {
      if (!sso[field]) {
        setError(errorMessages.MISSING_FIELD);
        formErrors++;
      }
    });
    if (!isValidConnectorName(sso.name)) {
      setError(errorMessages.INVALID_CONNECTOR_NAME);
      formErrors++;
    }
    return formErrors === 0;
  };

  return (
    <>
      {sso.sso_id && (
        <div className="sso__title">
          <h2>{sso.name}</h2>
          <div className="sso__domain">
            <Link to={`/admin/edit-sso/${sso.sso_id}/domains`}>
              <Button variant="outlined" endIcon={<EditIcon />}>
                Edit domains
              </Button>
            </Link>
          </div>
        </div>
      )}

      <form className="admin__form" onSubmit={handleSubmit}>
        <CustomSelect
          id="sso_type"
          name="sso_type"
          label="Type"
          value={sso?.sso_type || ""}
          options={SSO_TYPE_OPTIONS}
          handleChange={handleChange}
        />
        <CustomInput
          id="name"
          name="name"
          label="Name"
          value={sso?.name || ""}
          handleChange={handleChange}
        />
        <CustomInput
          id="identifier"
          name="identifier"
          label="Identifier"
          value={sso?.identifier || ""}
          handleChange={handleChange}
        />
        <CustomInput
          id="secret"
          name="secret"
          label="Secret Key"
          value={sso?.secret || ""}
          handleChange={handleChange}
        />
        <CustomInput
          id="connector"
          name="connector"
          label="Connector"
          value={sso?.connector || ""}
          handleChange={handleChange}
        />
        <CustomInput
          id="scope"
          name="scope"
          label="Scope"
          value={sso?.scope || ""}
          handleChange={handleChange}
        />
        <CustomInput
          id="certificate"
          name="certificate"
          label="Certificate"
          value={sso?.certificate || ""}
          handleChange={handleChange}
        />
        <CustomSelect
          id="status"
          name="status"
          label="Status"
          value={sso?.status || ""}
          options={SSO_STATUS_OPTIONS}
          handleChange={handleChange}
        />

        <div className="admin__form__submit">
          <Button variant="contained" type="submit" fullWidth>
            {isLoading ? <Loader /> : "Submit"}
          </Button>
        </div>
        {apiError && <p className="error">{apiError}</p>}
        {error && <p className="error">{error}</p>}
      </form>

      <RenderModal>
        <div className="modal">
          <div className="modal__title">Please confirm to submit: </div>
          <div className="modal__form">
            <div>
              <span>Type: </span>
              <span>{sso.sso_type}</span>
            </div>
            <div>
              <span>Name: </span>
              <span>{sso.name}</span>
            </div>
            <div>
              <span>Identifier: </span>
              <span>{sso.identifier}</span>
            </div>
            <div>
              <span>Secret Key: </span>
              <span>{sso.secret}</span>
            </div>
            <div>
              <span>Connector: </span>
              <span>{sso.connector}</span>
            </div>
            <div>
              <span>Scope: </span>
              <span>{sso.scope}</span>
            </div>
            <div>
              <span>Certificate: </span>
              <span>{sso.certificate}</span>
            </div>
            <div>
              <span>Status: </span>
              <span>{SSO_STATUS_OPTIONS[sso.status]}</span>
            </div>
          </div>
          <div className="modal__buttons">
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateSSO}
            >
              Confirm
            </Button>
            <Button variant="contained" onClick={hideModal}>
              Cancel
            </Button>
          </div>
        </div>
      </RenderModal>
    </>
  );
}
