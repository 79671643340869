import { useState } from "react";
import { useParams } from "react-router-dom";

import AdminBack from "../components/AdminBack";
import EditUserForm from "../components/EditUserForm";
import Loading from "../components/Loading";
import Error from "../components/Error";
import { useGetUser, useUpdateUser } from "../hooks/user";

export default function EditUser() {
  const { username } = useParams();

  const [apiError, setApiError] = useState("");

  const { data, isLoading, isError } = useGetUser(username);
  const { mutateAsync, isLoading: isUpdating } = useUpdateUser(username);

  const handleUpdateUser = async (data) => {
    try {
      await mutateAsync(data);
    } catch (error) {
      setApiError(error.message);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Error />;
  }

  return (
    <div className="container">
      <AdminBack />
      <EditUserForm
        userData={data}
        onFormSubmit={handleUpdateUser}
        isLoading={isUpdating}
        apiError={apiError}
        setApiError={setApiError}
      />
    </div>
  );
}
