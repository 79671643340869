import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import { isValidDomain } from "../helpers";
import { errorMessages } from "../constants";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
}));

export default function EditDomainForm({ domain, updateDomain, cancelUpdate }) {
  const [domainName, setDomainName] = useState(domain?.domain);
  const [client, setClient] = useState(domain?.forward_for);
  const [error, setError] = useState("");

  const classes = useStyles();

  const handleClickUpdate = () => {
    if (!isValidDomain(domainName)) {
      setError(errorMessages.INVALID_DOMAIN);
    } else {
      const updatedDomain = {
        ...domain,
        domain: domainName,
        forward_for: client,
      };
      updateDomain(updatedDomain);
    }
  };

  const handleCancelUpdate = () => {
    cancelUpdate();
    setDomainName(domain.domain);
    setClient(domain.forward_for);
  };

  return (
    <div className="domain">
      <div>
        <TextField
          label="Domain"
          value={domainName}
          onChange={(e) => setDomainName(e.target.value)}
          onFocus={() => setError("")}
          error={!!error}
          helperText={error}
          className={classes.textField}
        />
        <TextField
          label="Client"
          value={client}
          onChange={(e) => setClient(e.target.value)}
          className={classes.textField}
        />
      </div>
      <div className="domain__buttons">
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleClickUpdate}
        >
          Update
        </Button>
        <Button size="small" variant="contained" onClick={handleCancelUpdate}>
          Cancel
        </Button>
      </div>
    </div>
  );
}
