import { useState, useEffect } from "react";

const usePasswordValidation = ({ password1 = "", password2 = "" }) => {
  const [isValidLength, setIsValidLength] = useState(null);
  const [hasUpperCase, setHasUpperCase] = useState(null);
  const [hasLowerCase, setHasLowerCase] = useState(null);
  const [hasNumber, setHasNumber] = useState(null);
  const [hasSpecialChar, setHasSpecialChar] = useState(null);
  const [isMatch, setIsMatch] = useState(null);

  useEffect(() => {
    setIsValidLength(password1.length >= 8 ? true : false);
    setHasUpperCase(password1.toLowerCase() !== password1);
    setHasLowerCase(password1.toUpperCase() !== password1);
    setHasNumber(/\d/.test(password1));
    setHasSpecialChar(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password1));
    setIsMatch(password1 && password1 === password2);
  }, [password1, password2]);

  return [
    isValidLength,
    hasUpperCase,
    hasLowerCase,
    hasNumber,
    hasSpecialChar,
    isMatch,
  ];
};

export default usePasswordValidation;
