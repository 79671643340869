import React from "react";

export default function ResetPasswordRules({
  error,
  isValidLength,
  hasUpperAndLowerCase,
  hasNumber,
  hasSpecialChar,
}) {
  return (
    <div className="password_rules">
      <p
        className={
          isValidLength ? "text--blue" : error ? "text--red" : "text--light"
        }
      >
        Minimum 8 characters
      </p>

      <p
        className={
          hasUpperAndLowerCase
            ? "text--blue"
            : error
            ? "text--red"
            : "text--light"
        }
      >
        Contain 1 uppercase and 1 lowercase
      </p>

      <p
        className={
          hasNumber ? "text--blue" : error ? "text--red" : "text--light"
        }
      >
        Contain 1 numeric character
      </p>
      <p
        className={
          hasSpecialChar ? "text--blue" : error ? "text--red" : "text--light"
        }
      >
        Contain special character (!@#$%^&*()-+)
      </p>
    </div>
  );
}
