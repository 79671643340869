import { useState } from "react";
import { useQueryClient, useMutation } from "react-query";
import { useHistory } from "react-router-dom";

import AdminBack from "../components/AdminBack";
import CreateUserForm from "../components/CreateUserForm";
import { useCreateUser } from "../hooks/user";

const userData = {
  email_addr: "",
  user_type: "",
  status: "",
  locale: "",
  sendEmail: true,
};

export default function CreateUser() {
  const history = useHistory();
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState("");

  const { mutateAsync, isLoading } = useCreateUser();

  const handleCreate = async (user) => {
    setApiError("");
    try {
      await mutateAsync(user);
      queryClient.invalidateQueries("users");
      history.push("/admin");
    } catch (error) {
      setApiError(error.message);
    }
  };

  return (
    <div className="container">
      <AdminBack />
      <CreateUserForm
        userData={userData}
        onFormSubmit={handleCreate}
        isLoading={isLoading}
        apiError={apiError}
        setApiError={setApiError}
      />
    </div>
  );
}
