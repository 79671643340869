import { useState } from "react";

import usePasswordValidation from "../hooks/UsePasswordValidation";
import ResetPasswordRules from "./ResetPasswordRules";
import { changePassword } from "../api/api";
import { pwdErrorMapper } from "../helpers";
import { errorMessages } from "../constants";
import Loader from "../components/Loader";

function ResetPasswordForm({ token, setPasswordResetSuccess }) {
  const [password1, setPassword1] = useState("");
  const [isPassword1Focused, setIsPassword1Focused] = useState(true);
  const [password2, setPassword2] = useState("");
  const [isPassword2Focused, setIsPassword2Focused] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [
    isValidLength,
    hasUpperCase,
    hasLowerCase,
    hasNumber,
    hasSpecialChar,
    isMatch,
  ] = usePasswordValidation({ password1, password2 });

  const isStrongPassword =
    isValidLength &&
    hasUpperCase &&
    hasLowerCase &&
    hasNumber &&
    hasSpecialChar;

  function handleOnSubmit(e) {
    e.preventDefault();
    if (!password1) {
      setError(errorMessages.NO_PASSWORD);
    } else if (!isMatch) {
      setError(errorMessages.NO_MATCH);
    } else if (!isStrongPassword) {
      setError(errorMessages.TOO_WEAK);
    } else {
      handleChangePassword(password1, password2);
    }
  }

  function handleChangePassword(password1, password2) {
    setIsLoading(true);
    changePassword(token, password1, password2)
      .then((response) => {
        if (response && response.status === 204) {
          setPasswordResetSuccess(true);
        } else if (response && response.status === 400) {
          return response.json().then((data) => {
            // password not passing API validation rules
            if (data.rules) {
              const error = data.rules[0];
              setError(pwdErrorMapper[error]);
              setIsLoading(false);
            } else {
              setError(errorMessages.GENERIC);
              setIsLoading(false);
            }
          });
        } else {
          setError(errorMessages.GENERIC);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setError(errorMessages.GENERIC);
        setIsLoading(false);
      });
  }

  function handleOnFocusPassword1() {
    setError("");
    setIsPassword1Focused(true);
  }

  function handleOnFocusPassword2() {
    setError("");
    setIsPassword2Focused(true);
  }

  let input1Class = "form-control";
  let input2Class = "form-control";

  if (error) {
    input1Class += " form-control--error";
    input2Class += " form-control--error";
  } else if (isPassword1Focused && password1.length > 0) {
    input1Class += " form-control--focus";
    if (isPassword2Focused && password2.length > 0) {
      input2Class += " form-control--focus";
    }
  }

  return (
    <>
      <p className="login__title">Create new password</p>
      <ResetPasswordRules
        error={error}
        isValidLength={isValidLength}
        hasUpperAndLowerCase={hasUpperCase && hasLowerCase}
        hasNumber={hasNumber}
        hasSpecialChar={hasSpecialChar}
      />
      <form onSubmit={handleOnSubmit}>
        <div className="login__input">
          <input
            className={input1Class}
            placeholder="New Password"
            type="password"
            name="password1"
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
            onFocus={handleOnFocusPassword1}
            autoFocus
          />
        </div>
        <div className="login__input">
          <input
            className={input2Class}
            placeholder="Confirm password"
            type="password"
            name="password2"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            onFocus={handleOnFocusPassword2}
          />
        </div>
        <button type="submit" className="btn login__btn" disabled={isLoading}>
          {isLoading ? <Loader /> : "Save new password"}
        </button>
      </form>

      <p className="error">{error}</p>
    </>
  );
}

export default ResetPasswordForm;
