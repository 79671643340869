import { useContext } from "react";

import { AppContext } from "../App";
import LandingPage from "../components/LandingPage";
import Card from "../components/Card";
import GenericLogo from "../images/Logo.png";
import LoginForm from "../components/LoginForm";
import { checkAdmin } from "../api/api";
import { ADMIN_LOGIN } from "../constants";

function AdminLogin() {
  const { callbackUrl } = useContext(AppContext);

  return (
    <LandingPage>
      <Card logo={GenericLogo}>
        <p className="login__title">
          Login to <span>admin console</span>
        </p>
        <LoginForm
          callbackUrl={callbackUrl}
          checkUsername={checkAdmin}
          loginType={ADMIN_LOGIN}
        />
      </Card>
    </LandingPage>
  );
}

export default AdminLogin;
