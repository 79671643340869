import { useState, useEffect } from "react";

import LandingPage from "../components/LandingPage";
import Card from "../components/Card";
import Loader from "../components/Loader";
import ResetPasswordForm from "../components/ResetPasswordForm";
import ResetPasswordSuccess from "../components/ResetPasswordSuccess";
import ResetPasswordError from "../components/ResetPasswordError";
import { verifyPasswordResetToken } from "../api/api";
import { getQueryParam } from "../helpers";

function ResetPassword() {
  const [token, setToken] = useState(null);
  const [isTokenChecked, setIsTokenChecked] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);

  useEffect(() => {
    const token = getQueryParam("token");
    if (token) {
      verifyPasswordResetToken(token)
        .then((response) => {
          // token valid
          if (response && response.status === 200) {
            return response.json().then(() => {
              setIsTokenChecked(true);
              setIsTokenValid(true);
              setToken(token);
            });
            // token invalid
          } else if (response && response.status === 400) {
            setIsTokenChecked(true);
            setIsTokenValid(false);
          }
        })
        .catch(() => {
          setIsTokenChecked(true);
          setIsTokenValid(false);
        });
    } else {
      setIsTokenChecked(true);
      setIsTokenValid(false);
    }
  }, []);

  if (!isTokenChecked) {
    return (
      <div className="container">
        <Loader />
      </div>
    );
  }

  return (
    <LandingPage>
      <Card>
        {isTokenChecked && !isTokenValid ? (
          <ResetPasswordError />
        ) : passwordResetSuccess ? (
          <ResetPasswordSuccess />
        ) : (
          <ResetPasswordForm
            token={token}
            setPasswordResetSuccess={setPasswordResetSuccess}
          />
        )}
      </Card>
    </LandingPage>
  );
}

export default ResetPassword;
