import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@material-ui/data-grid";
import { useHistory } from "react-router-dom";

const columns = [
  {
    field: "username",
    headerName: "Username",
    sortComparator: (a, b) => {
      const domainA = a.split("@")[1];
      const domainB = b.split("@")[1];
      if (domainA < domainB) {
        return -1;
      }
      if (domainA > domainB) {
        return 1;
      }
      return 0;
    },
    width: 300,
  },
  { field: "email_addr", headerName: "Email", width: 300 },
  { field: "locale", headerName: "Locale", width: 150 },
  { field: "expiration_days", headerName: "Exp", width: 150 },
  { field: "status", headerName: "Status", width: 150 },
  { field: "user_type", headerName: "Type", width: 150 },
];

export default function UsersTable({ users }) {
  const history = useHistory();

  const handleRowClick = (param, event) => {
    history.push(`/admin/edit-user/${param.id}`);
  };

  return (
    <div style={{ height: 600, width: "100%", margin: "40px auto" }}>
      <DataGrid
        rows={users}
        columns={columns}
        pageSize={100}
        rowHeight={35}
        onRowClick={handleRowClick}
        getRowId={(row) => row.username}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}
