import { useState } from "react";
import { useParams } from "react-router-dom";

import AdminBack from "../components/AdminBack";
import ConnectorForm from "../components/ConnectorForm";
import Loading from "../components/Loading";
import Error from "../components/Error";
import { useGetConnector, useUpdateConnector } from "../hooks/connector";

export default function EditConnector() {
  const { id } = useParams();
  const [apiError, setApiError] = useState("");

  const { data, isLoading, isError } = useGetConnector(id);
  const { mutateAsync, isLoading: isUpdating } = useUpdateConnector(id);

  const handleUpdateSSO = async (data) => {
    try {
      await mutateAsync(data);
    } catch (error) {
      setApiError(error.message);
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Error />;
  }

  return (
    <div className="container">
      <AdminBack />
      <ConnectorForm
        ssoData={data}
        onFormSubmit={handleUpdateSSO}
        isLoading={isUpdating}
        apiError={apiError}
        setApiError={setApiError}
        submitText="UPDATE SSO"
      />
    </div>
  );
}
