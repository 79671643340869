import { Link } from "react-router-dom";
import { Mail } from "@material-ui/icons";

export default function ConfirmationEmailSent() {
  return (
    <div className="text--center">
      <Mail style={{ fontSize: 50 }} />
      <p className="text--semibold">Check your mail</p>
      <p className="text--light">
        If we have your information on file, you will receive an email with
        instructions on how to reset your password.
      </p>
      <Link to="/" className="link text--dark">
        Back to Login
      </Link>
    </div>
  );
}
