import { useState } from "react";
import { Link } from "react-router-dom";

import Loader from "../components/Loader";
import { login } from "../api/api";
import { errorMessages, ADMIN_LOGIN } from "../constants";

function LoginForm({ callbackUrl, checkUsername, loginType }) {
  const [username, setUsername] = useState("");
  const [isUsernameFocused, setIsUsernameFocused] = useState(true);
  const [password, setPassword] = useState("");
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);
  const [isUserChecked, setIsUserChecked] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function handleSubmit(e) {
    return isUserChecked ? handleLogin(e) : handleUsername(e);
  }

  function handleUsername(e) {
    e.preventDefault();
    setIsLoading(true);
    const trimmedUsername = username.trim();
    if (trimmedUsername) {
      checkUsername(trimmedUsername, callbackUrl)
        .then((response) => {
          // 200 = SSO (Single Sign On) user
          if (response.status === 200) {
            return response.json().then((data) => {
              // using location.replace() for redirects so user can't use the back button to navigate back once logged in.
              window.location.replace(data.redirect);
            });
            // local user or user not found
          } else {
            setIsUserChecked(true);
            setUsername(trimmedUsername);
            setIsLoading(false);
          }
        })
        .catch(() => {
          setError(errorMessages.GENERIC);
          setIsLoading(false);
        });
    } else {
      setError(errorMessages.INVALID_EMAIL);
      setIsLoading(false);
    }
  }

  function handleLogin(e) {
    e.preventDefault();
    setIsLoading(true);
    const trimmedUsername = username.trim();
    if (!trimmedUsername && !password) {
      setError(errorMessages.NO_USERNAME_NO_PASSWORD);
      setIsLoading(false);
    } else if (!trimmedUsername) {
      setError(errorMessages.NO_USERNAME);
      setIsLoading(false);
    } else if (!password) {
      setError(errorMessages.NO_PASSWORD);
      setIsLoading(false);
    } else {
      login(trimmedUsername, password, callbackUrl)
        .then((response) => {
          if (response && response.status === 200) {
            return response.json().then((data) => {
              window.location.replace(data.redirect);
            });
          } else if (response && response.status === 429) {
            setError(errorMessages.LOCKED_OUT);
            setIsLoading(false);
          } else if (response && response.status === 401) {
            return response.json().then((data) => {
              setError(
                data.expired
                  ? errorMessages.PASSWORD_EXPIRED
                  : errorMessages.LOGIN_FAILED
              );
              setIsLoading(false);
            });
          } else {
            setError(errorMessages.LOGIN_FAILED);
            setIsLoading(false);
          }
        })
        .catch(() => {
          setError(errorMessages.LOGIN_FAILED);
          setIsLoading(false);
        });
    }
  }

  function handleOnFocusUsername() {
    setError("");
    setIsUsernameFocused(true);
  }

  function handleOnFocusPassword() {
    setError("");
    setIsPasswordFocused(true);
  }

  function handleOnClickUsername() {
    if (isUserChecked) {
      setIsUserChecked(false);
      setUsername("");
      setPassword("");
      setError("");
    }
  }

  let inputUsernameClass = "form-control";
  let inputPasswordClass = "form-control";

  if (error) {
    inputUsernameClass += " form-control--error";
    inputPasswordClass += " form-control--error";
  } else if (isUsernameFocused && username.length > 0) {
    inputUsernameClass += " form-control--focus";
    if (isPasswordFocused || password.length > 0) {
      inputPasswordClass += " form-control--focus";
    }
  }

  const PwDisplayClass = isUserChecked ? null : "hidden";

  const submitBtnText = isLoading ? (
    <Loader />
  ) : isUserChecked ? (
    "Login"
  ) : (
    "Continue"
  );

  return (
    <>
      <form className="login__form" onSubmit={handleSubmit}>
        <div className="login__input">
          <input
            className={`clickable ${inputUsernameClass}`}
            placeholder="Email Address"
            type="text"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            onFocus={handleOnFocusUsername}
            onClick={handleOnClickUsername}
          />
        </div>
        <div className={`login__input ${PwDisplayClass}`}>
          <input
            className={inputPasswordClass}
            placeholder="Password"
            type="password"
            name="password"
            autoComplete="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onFocus={handleOnFocusPassword}
            autoFocus
          />
        </div>
        <p className="error">{error}</p>
        <button type="submit" className="btn login__btn" disabled={isLoading}>
          {submitBtnText}
        </button>
      </form>
      {loginType !== ADMIN_LOGIN && isUserChecked && (
        <Link to="/forgot-password" className="link text--light">
          Forgot password
        </Link>
      )}
    </>
  );
}

export default LoginForm;
