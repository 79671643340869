import Logo from "../components/Logo";
import HeaderMenu from "../components/HeaderMenu";

export default function Header({ showSSOLink }) {
  return (
    <div className="header">
      <Logo />
      <HeaderMenu showSSOLink={showSSOLink} />
    </div>
  );
}
