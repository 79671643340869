import { useParams } from "react-router-dom";

import AdminBack from "../components/AdminBack";
import DomainList from "../components/DomainList";
import AddDomainForm from "../components/AddDomainForm";
import Loading from "../components/Loading";
import Error from "../components/Error";
import { useGetDomains } from "../hooks/domain";

export default function Domains() {
  const { id: sso_id } = useParams();
  const { data, isLoading, isError } = useGetDomains(sso_id);

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Error />;
  }

  return (
    <div className="container">
      <AdminBack />
      <AddDomainForm sso_id={sso_id} />
      {data.length > 0 && <h2 className="domain__title">List of domains:</h2>}
      <DomainList domains={data} />
    </div>
  );
}
