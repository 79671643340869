import { Switch, Route } from "react-router-dom";

import Header from "../components/Header";
import Users from "./Users";
import CreateUser from "./CreateUser";
import EditUser from "./EditUser";
import Connectors from "./Connectors";
import CreateConnector from "./CreateConnector";
import Domains from "./Domains";
import EditConnector from "./EditConnector";
import { useGetConnectors } from "../hooks/connector";

const Admin = () => {
  // Only users with the correct privileges can access connectors.
  // If /connectors endpoint returns 403 then data will be undefined
  // and the sso link will be removed from navigation menu.
  // TODO: set up endpoint to get user status instead of inferring it from the connectors response code.
  const { data: connectors } = useGetConnectors();

  return (
    <>
      <Header showSSOLink={!!connectors} />
      <div>
        <Switch>
          <Route exact path="/admin">
            <Users />
          </Route>
          <Route path="/admin/create-user">
            <CreateUser />
          </Route>
          <Route path="/admin/edit-user/:username">
            <EditUser />
          </Route>
          <Route path="/admin/sso">
            <Connectors />
          </Route>
          <Route path="/admin/create-sso">
            <CreateConnector />
          </Route>
          <Route path="/admin/edit-sso/:id/domains">
            <Domains />
          </Route>
          <Route path="/admin/edit-sso/:id">
            <EditConnector />
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default Admin;
