import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";

import ConnectorsTable from "../components/ConnectorsTable";
import Loading from "../components/Loading";
import Error from "../components/Error";
import AdminBack from "../components/AdminBack";
import { useGetConnectors } from "../hooks/connector";

export default function Connectors() {
  const { data: connectors, isLoading, isError } = useGetConnectors();

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <Error />;
  }

  return (
    <div class="dashboard">
      <AdminBack />
      <ConnectorsTable connectors={connectors} />
      <Link to="/admin/create-sso">
        <Button variant="contained" color="primary">
          Create SSO
        </Button>
      </Link>
    </div>
  );
}
