import "url-search-params-polyfill";
import { ISPOT_STATIC } from "./constants";

export function getQueryParam(param) {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(param);
}

export function getAdImage(short_url, ispot_url, thumb_id = 3) {
  return `${ISPOT_STATIC}/ad/${short_url}/${ispot_url}-large-${thumb_id}.jpg`;
}

export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export const pwdErrorMapper = {
  min: "Password must be at least 8 characters long",
  max: "Password must be less than 70 characters long",
  lowercase: "Password must have at least one lowercase character",
  uppercase: "Password must have at least one uppercase character",
  digits: "Password must have at least one digit",
  symbols: "Password must have at least one symbol",
  spaces: "Password cannot contain spaces",
  oneOf: "Please choose a stronger password",
  too_frequent: "Password may not be similar to last 10 passwords",
  hashing_error: "Failed to change password",
};
