import { useEffect, useState, createContext } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "url-search-params-polyfill";

import "./App.css";
import AppRouter from "./routers/AppRouter";
import { getTopAd } from "./api/api";
import { getQueryParam } from "./helpers";
import {
  FALLBACK_BG_IMG,
  FALLBACK_AD_TITLE,
  ACE_LOCALE,
  ISPOT_LOCALE,
} from "./constants";
import GenericLogo from "./images/Logo.png";
import AceLogo from "./images/Logos.jpg";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 86400000, // 24 hours
      cacheTime: 3600000, // 1 hour
    },
  },
});

export const AppContext = createContext();

function App() {
  const [bgImage, setBgImage] = useState("");
  const [adTitle, setAdTitle] = useState("");
  const [callbackUrl, setCallbackUrl] = useState(null);
  const [logo, setLogo] = useState(GenericLogo);
  const [locale, setLocale] = useState(ISPOT_LOCALE);

  useEffect(() => {
    // get background img
    getTopAd()
      .then((json) => {
        const { adName, adImgLg } = json.data;
        setBgImage(adImgLg);
        setAdTitle(adName);
      })
      .catch(() => {
        setBgImage(FALLBACK_BG_IMG);
        setAdTitle(FALLBACK_AD_TITLE);
      });
  }, []);

  useEffect(() => {
    // get locale and logo based on callback query param
    const callbackParam = getQueryParam("callback");
    if (callbackParam) {
      setCallbackUrl(decodeURIComponent(callbackParam));
      if (callbackParam.includes(ACE_LOCALE)) {
        setLocale(ACE_LOCALE);
        setLogo(AceLogo);
      }
    }
  }, []);

  return (
    <AppContext.Provider
      value={{ bgImage, adTitle, callbackUrl, logo, locale }}
    >
      <QueryClientProvider client={queryClient}>
        <img
          // This <img> is never displayed. It's just here to check if the default background img exist
          // (since onError is not available with the css background-image property).
          // Use a fallback background img and its corresponding ad title if the default image does not exist.
          // See https://stackoverflow.com/a/62714809
          alt=""
          style={{ display: "none" }}
          src={bgImage}
          onError={(e) => {
            setBgImage(FALLBACK_BG_IMG);
            setAdTitle(FALLBACK_AD_TITLE);
          }}
        />
        <AppRouter />
        <ReactQueryDevtools />
      </QueryClientProvider>
    </AppContext.Provider>
  );
}

export default App;
