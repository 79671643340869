import List from "@material-ui/core/List";
import DomainListItem from "./DomainListItem";

export default function DomainList({ domains }) {
  return (
    <List>
      {domains.length > 0 &&
        domains
          .sort((a, b) => a.domain.localeCompare(b.domain))
          .map((domain) => (
            <DomainListItem key={domain.domain_id} domain={domain} />
          ))}
    </List>
  );
}
