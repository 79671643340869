import { useQuery, useQueryClient, useMutation } from "react-query";

import * as api from "../api/api";
import { useHistory } from "react-router-dom";

export function useGetUsers() {
  return useQuery("users", api.getUsers);
}

export function useGetUser(username) {
  return useQuery(["user", { username }], api.getUser);
}

export function useCreateUser() {
  const history = useHistory();
  const cache = useQueryClient();

  return useMutation(api.createUser, {
    onSuccess: () => {
      cache.invalidateQueries("users");
      history.push("/admin");
    },
  });
}

export function useUpdateUser(username) {
  const history = useHistory();
  const cache = useQueryClient();

  return useMutation(api.updateUser, {
    onSuccess: () => {
      cache.invalidateQueries(["user", { username }]);
      cache.invalidateQueries("users");
      history.push("/admin");
    },
  });
}
