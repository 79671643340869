import { errorMessages } from "../constants";

const headers = { "Content-Type": "application/json" };

export const getUsers = async () => {
  const response = await fetch(`/admin/users`);
  if (!response.ok) {
    throw new Error(errorMessages.GENERIC);
  }
  return response.json();
};

export const getUser = async ({ queryKey }) => {
  /* eslint-disable no-unused-vars */
  const [_key, { username }] = queryKey;
  const response = await fetch(`/admin/user/${username}`);
  if (!response.ok) {
    throw new Error(errorMessages.GENERIC);
  }
  return response.json();
};

export const createUser = async (data) => {
  const response = await fetch(`/admin/user`, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  });
  if (response.status === 409) {
    throw new Error("This user already exists");
  }
  if (!response.ok) {
    throw new Error(errorMessages.GENERIC);
  }
  return true;
};

export const updateUser = async (data) => {
  const response = await fetch(`/admin/user`, {
    method: "PUT",
    headers,
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error(errorMessages.GENERIC);
  }
  return true;
};

export const getConnectors = async () => {
  const response = await fetch(`/admin/connectors`);
  if (response.status === 403) {
    throw new Error("Unauthorized");
  }
  if (!response.ok) {
    throw new Error(errorMessages.GENERIC);
  }
  return response.json();
};

export const getConnector = async ({ queryKey }) => {
  /* eslint-disable no-unused-vars */
  const [_key, { id }] = queryKey;
  const response = await fetch(`/admin/connector/${id}`);
  if (!response.ok) {
    throw new Error(errorMessages.GENERIC);
  }
  return response.json();
};

export const createConnector = async (data) => {
  const response = await fetch(`/admin/connector`, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  });
  if (response.status === 409) {
    throw new Error("This connector already exists");
  }
  if (!response.ok) {
    throw new Error(errorMessages.GENERIC);
  }
  return true;
};

export const updateConnector = async (data) => {
  const response = await fetch(`/admin/connector`, {
    method: "PUT",
    headers,
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error(errorMessages.GENERIC);
  }
  return true;
};

export const getDomains = async ({ queryKey }) => {
  /* eslint-disable no-unused-vars */
  const [_key, { sso_id }] = queryKey;
  const response = await fetch(`/admin/domain/${sso_id}`);
  if (!response.ok) {
    throw new Error(errorMessages.GENERIC);
  }
  return response.json();
};

export const addDomain = async (data) => {
  const response = await fetch(`/admin/domain`, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  });
  if (response.status === 409) {
    throw new Error("This domain already exists");
  }
  if (!response.ok) {
    throw new Error(errorMessages.GENERIC);
  }
  return true;
};

export const updateDomain = async (data) => {
  const response = await fetch(`/admin/domain`, {
    method: "PUT",
    headers,
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error(errorMessages.GENERIC);
  }
  return true;
};

export const deleteDomain = async (data) => {
  const response = await fetch(`/admin/domain`, {
    method: "DELETE",
    headers,
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error(errorMessages.GENERIC);
  }
  return true;
};
