import React from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

export default function AdminBack() {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className="back">
      <Button onClick={goBack}>
        <ArrowBackIosIcon />
        Back
      </Button>
    </div>
  );
}
