export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function isValidConnectorName(name) {
  const specialCharRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return name && !specialCharRegex.test(name);
}

export function isValidDomain(domain) {
  return domain && domain.length > 0;
}
