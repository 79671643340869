import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Login from "../views/Login";
import ForgotPassword from "../views/ForgotPassword";
import ResetPassword from "../views/ResetPassword";
import AdminLogin from "../views/AdminLogin";
import Admin from "../admin/views/Admin";

export default function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <Route path="/forgot-password">
          <ForgotPassword />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route exact path="/admin-login">
          <AdminLogin />
        </Route>
        <Route path="/admin">
          <Admin />
        </Route>
      </Switch>
    </Router>
  );
}
