import { useState } from "react";

import Dialog from "@material-ui/core/Dialog";

const useModal = () => {
  const [isVisible, setIsVisible] = useState(false);

  const showModal = () => setIsVisible(true);
  const hideModal = () => setIsVisible(false);

  const RenderModal = ({ children }) => (
    <>
      {isVisible && (
        <Dialog
          open={isVisible}
          onClose={hideModal}
          aria-labelledby="confirmation-modal"
          aria-describedby="confirmation-modal"
        >
          {children}
        </Dialog>
      )}
    </>
  );

  return {
    showModal,
    hideModal,
    RenderModal,
  };
};

export default useModal;
