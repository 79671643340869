import { useState } from "react";
import { useParams } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import EditDomainForm from "./EditDomainForm";
import useModal from "../hooks/useModal";
import { useDomainMutation } from "../hooks/domain";

export default function DomainListItem({ domain }) {
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState("");
  const { showModal, hideModal, RenderModal } = useModal();

  const { id: sso_id } = useParams();

  const { updateDomain } = useDomainMutation(sso_id, (err) =>
    setError(err.message)
  );

  const { deleteDomain } = useDomainMutation(sso_id, (err) =>
    setError(err.message)
  );

  const handleUpdateDomain = (domain) => {
    updateDomain(domain);
    setIsEditing(false);
  };

  const handleClickDelete = () => {
    hideModal();
    deleteDomain(domain);
  };

  const handleClickUpdate = () => {
    setIsEditing(true);
    setError("");
  };

  const handleCancelUpdate = () => {
    setIsEditing(false);
  };

  return (
    <>
      {isEditing ? (
        <EditDomainForm
          domain={domain}
          updateDomain={handleUpdateDomain}
          cancelUpdate={handleCancelUpdate}
        />
      ) : (
        <div className="domain">
          <div>{domain.domain} </div>
          {domain.forward_for && <div>Client: {domain.forward_for}</div>}
          <div>
            <ListItemIcon onClick={handleClickUpdate}>
              <Tooltip title="Edit">
                <IconButton edge="end" aria-label="edit">
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </ListItemIcon>
            <ListItemIcon onClick={showModal}>
              <Tooltip title="Delete">
                <IconButton edge="end" aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ListItemIcon>
          </div>
        </div>
      )}
      {error && <p className="error">{error}</p>}
      <Divider />

      <RenderModal>
        <div className="modal">
          <div className="modal__title">
            Are you sure you want to delete this domain?
          </div>
          <div className="modal__buttons">
            <Button
              variant="contained"
              color="secondary"
              startIcon={<DeleteIcon />}
              onClick={handleClickDelete}
            >
              Delete
            </Button>
            <Button variant="contained" onClick={hideModal}>
              Cancel
            </Button>
          </div>
        </div>
      </RenderModal>
    </>
  );
}
