export const errorMessages = {
  GENERIC: "Oops! Something went wrong!",
  INVALID_EMAIL: "Please enter a valid email address",
  INVALID_CONNECTOR_NAME: "Please enter a valid name",
  INVALID_DOMAIN: "Please enter a valid domain",
  MISSING_FIELD: "Required field missing",
};

export const USER_TYPE_OPTIONS = {
  a: "Admin",
  u: "User",
};

export const USER_STATUS_OPTIONS = {
  a: "Active",
  d: "Disabled",
  r: "Reset Password",
};

export const EXPIRATION_DAYS_OPTIONS = {
  30: 30,
  60: 60,
  90: 90,
  180: 180,
  365: 365,
};

export const LOCALE_OPTIONS = {
  ace: "Ace",
  ispot: "iSpot",
};

export const SSO_TYPE_OPTIONS = {
  oidc: "OIDC",
  saml: "SAML",
};

export const SSO_STATUS_OPTIONS = {
  a: "Active",
  d: "Disabled",
};

export const ADMIN_LOGOUT_URL = "/api/logout?destination=admin-login";
