import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";

export default function CustomInput({ id, name, label, value, handleChange }) {
  return (
    <FormControl margin="dense">
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Input
        name={name}
        value={value}
        aria-describedby={id}
        onChange={handleChange}
      />
    </FormControl>
  );
}
