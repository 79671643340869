import { Link } from "react-router-dom";
import { Warning } from "@material-ui/icons";

export default function ResetPasswordError() {
  return (
    <div className="text--center">
      <Warning style={{ fontSize: 50 }} />
      <p className="text--semibold">Reset link is invalid</p>
      <p>
        Password reset link is invalid or expired. <br></br>Please click button
        to try again
      </p>
      <Link to="/forgot-password">
        <button type="submit" className="btn login__btn">
          Resend link
        </button>
      </Link>
    </div>
  );
}
