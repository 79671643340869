const headers = { "Content-Type": "application/json" };

// Top ad
export const getTopAd = async () => {
  const response = await fetch("/ad/top-ad");
  return response.json();
};

// User
export const checkSession = async (callback) => {
  const queryParams = callback ? `?callback=${callback}` : "";
  const response = await fetch(`/api/session${queryParams}`);
  return response;
};

export const checkUser = async (username, callback) => {
  const response = await fetch("/api/username", {
    method: "POST",
    headers,
    body: JSON.stringify({ username, callback }),
  });
  return response;
};

export const checkAdmin = async (username, callback) => {
  const response = await fetch("api/admin/username", {
    method: "POST",
    headers,
    body: JSON.stringify({ username, callback }),
  });
  return response;
};

export const login = async (username, password, callback) => {
  const response = await fetch("/api/login", {
    method: "POST",
    headers,
    body: JSON.stringify({ username, password, callback }),
  });
  return response;
};

export const logout = async () => {
  const response = await fetch("/api/logout");
  return response;
};

export const sendPasswordResetLink = async (username) => {
  const response = await fetch("/api/reset", {
    method: "POST",
    headers,
    body: JSON.stringify({ username }),
  });
  return response;
};

export const verifyPasswordResetToken = async (token) => {
  const response = await fetch("/api/reset/verify", {
    method: "POST",
    headers,
    body: JSON.stringify({ token }),
  });
  return response;
};

export const changePassword = async (token, password1, password2) => {
  const response = await fetch("/api/reset/change", {
    method: "POST",
    headers,
    body: JSON.stringify({ token, password1, password2 }),
  });
  return response;
};
