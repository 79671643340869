import PetSmart from "./images/PetSmart-bg.jpg";

export const errorMessages = {
  GENERIC: "An error occurred",
  INVALID_EMAIL: "Please enter a valid email address",
  LOGIN_FAILED: "Login failed",
  LOCKED_OUT: "Your account has been locked. Please try again in 10 minutes.",
  PASSWORD_EXPIRED:
    "Your password has expired. Please check your email for reset instructions.",
  NO_USERNAME: "Please enter an email address",
  NO_PASSWORD: "Please enter a password",
  NO_USERNAME_NO_PASSWORD: "Please enter an email and password",
  NO_MATCH: "Both passwords must match",
  TOO_WEAK: "Please use a stronger password",
  NO_SSO_PW_RESET:
    "Cannot reset password because single sign-on is used to authenticate this account.",
};

// Login types
export const ADMIN_LOGIN = "admin";
export const USER_LOGIN = "user";

// Locales
export const ISPOT_LOCALE = "ispot";
export const ACE_LOCALE = "acemetrix";

// Background image
export const ISPOT_STATIC = "https://d2z1w4aiblvrwu.cloudfront.net";
export const FALLBACK_BG_IMG = PetSmart;
export const FALLBACK_AD_TITLE = "PetSmart TV Spot, 'Anything for Pets";
