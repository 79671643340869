import { Link } from "react-router-dom";
import { CheckCircleOutline } from "@material-ui/icons";

export default function ResetPasswordSuccess() {
  return (
    <div className="text--center">
      <CheckCircleOutline style={{ fontSize: 50 }} />
      <p className="text--semibold">Successful password reset</p>
      <p>You can now use your new password to log in to your account!</p>
      <Link to="/">
        <button type="submit" className="btn login__btn">
          Go to login page
        </button>
      </Link>
    </div>
  );
}
