import GenericLogo from "../images/Logo.png";

export default function Card({ children, logo = GenericLogo, withBottom }) {
  return (
    <div className="card">
      <div className="card__top">
        <div>
          <img className="card__logo" src={logo} alt="logo"></img>
        </div>
        {children}
      </div>
      {withBottom && (
        <div className="card__bottom">
          <a
            className="link link--newToIspot"
            href="https://www.ispot.tv/signup"
          >
            New to iSpot.tv?
          </a>
        </div>
      )}
    </div>
  );
}
