import { useState } from "react";
import Button from "@material-ui/core/Button";

import CustomSelect from "./CustomSelect";
import CustomInput from "./CustomInput";
import CustomCheckbox from "./CustomCheckbox";
import Loader from "./Loader";
import useModal from "../hooks/useModal";
import { isValidEmail } from "../helpers";
import {
  USER_TYPE_OPTIONS,
  EXPIRATION_DAYS_OPTIONS,
  LOCALE_OPTIONS,
  errorMessages,
} from "../constants";

const requiredUserFields = [
  "username",
  "email_addr",
  "user_type",
  "expiration_days",
  "locale",
];

export default function CreateUserForm({
  onFormSubmit,
  isLoading,
  apiError,
  setApiError,
}) {
  const { showModal, hideModal, RenderModal } = useModal();
  const [user, setUser] = useState({ sendEmail: true });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value.trim() });
  };

  const handleCheckbox = () => {
    setUser((prevState) => ({
      ...prevState,
      sendEmail: !prevState.sendEmail,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setApiError("");
    if (validateUser(user)) {
      setError("");
      showModal();
    }
  };

  const handleSubmitUser = () => {
    hideModal();
    const userToSubmit = { ...user };
    // New user status default to recovery
    userToSubmit.status = "r";
    // Default to username if left blank
    userToSubmit.sso_username =
      user.sso_username !== "" ? user.sso_username : user.username;

    onFormSubmit(userToSubmit);
  };

  const validateUser = (user) => {
    let formErrors = 0;
    if (!isValidEmail(user.email_addr)) {
      setError(errorMessages.INVALID_EMAIL);
      formErrors++;
    }
    requiredUserFields.forEach((field) => {
      if (!user[field]) {
        setError(errorMessages.MISSING_FIELD);
        formErrors++;
      }
    });

    return formErrors === 0;
  };

  return (
    <>
      <form className="admin__form" onSubmit={handleSubmit}>
        <CustomInput
          id="username"
          name="username"
          label="Username"
          value={user?.username || ""}
          handleChange={handleChange}
        />
        <CustomInput
          id="sso_username"
          name="sso_username"
          label="SSO Username (optional)"
          value={user?.sso_username || ""}
          handleChange={handleChange}
        />
        <CustomInput
          id="email_addr"
          name="email_addr"
          label="Email"
          value={user?.email_addr || ""}
          handleChange={handleChange}
        />
        <CustomSelect
          id="user_type"
          name="user_type"
          label="User Type"
          value={user?.user_type || ""}
          options={USER_TYPE_OPTIONS}
          handleChange={handleChange}
        />
        <CustomSelect
          id="expiration_days"
          name="expiration_days"
          label="Password expiration"
          value={user?.expiration_days || ""}
          options={EXPIRATION_DAYS_OPTIONS}
          handleChange={handleChange}
        />
        <CustomSelect
          id="locale"
          name="locale"
          label="Locale"
          value={user?.locale || ""}
          options={LOCALE_OPTIONS}
          handleChange={handleChange}
        />
        <div className="admin__form__checkbox">
          <CustomCheckbox
            id="send_email"
            label="Send welcome email"
            checked={user?.sendEmail}
            handleChange={handleCheckbox}
          />
        </div>

        <div className="admin__form__submit">
          <Button type="submit" variant="contained" fullWidth>
            {isLoading ? <Loader /> : "CREATE USER"}
          </Button>
        </div>
        {apiError && <p className="error">{apiError}</p>}
        {error && <p className="error">{error}</p>}
      </form>

      <RenderModal>
        <div className="modal">
          <div className="modal__title">Please confirm: </div>
          <div className="modal__form">
            <div>
              <span>Username: </span>
              <span>{user.username}</span>
            </div>
            {user.sso_username && (
              <div>
                <span>SSO Username: </span>
                <span>{user.sso_username}</span>
              </div>
            )}
            <div>
              <span>Email: </span>
              <span>{user.email_addr}</span>
            </div>
            <div>
              <span>User Type: </span>
              <span>{USER_TYPE_OPTIONS[user.user_type]}</span>
            </div>
            <div>
              <span>Password Expiration (days): </span>
              <span>{user.expiration_days}</span>
            </div>
            <div>
              <span>Locale: </span>
              <span>{LOCALE_OPTIONS[user.locale]}</span>
            </div>
            <div>
              <span>Send welcome email: </span>
              <span>{user.sendEmail ? "Yes" : "No"}</span>
            </div>
          </div>
          <div className="modal__buttons">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmitUser}
            >
              Confirm
            </Button>
            <Button variant="contained" onClick={hideModal}>
              Cancel
            </Button>
          </div>
        </div>
      </RenderModal>
    </>
  );
}
