import React from "react";
import Loader from "./Loader";

export default function Loading() {
  return (
    <div className="container">
      <Loader />
    </div>
  );
}
