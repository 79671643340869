import { useState, useContext } from "react";
import { Link } from "react-router-dom";

import { AppContext } from "../App";
import LandingPage from "../components/LandingPage";
import Card from "../components/Card";
import ConfirmationEmailSent from "../components/ConfirmationEmailSent";
import Loader from "../components/Loader";
import { sendPasswordResetLink } from "../api/api";
import { errorMessages } from "../constants";

function ForgotPassword() {
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { logo } = useContext(AppContext);

  function handleResetPassword(e) {
    e.preventDefault();
    setIsLoading(true);
    const trimmedUsername = username.trim();
    if (trimmedUsername) {
      return sendPasswordResetLink(trimmedUsername)
        .then((response) => {
          if (response.status === 403) {
            setError(errorMessages.NO_SSO_PW_RESET);
            setIsLoading(false);
          } else {
            setIsEmailSent(true);
          }
        })
        .catch(() => {
          setError(errorMessages.GENERIC);
          setIsLoading(false);
        });
    } else {
      setError(errorMessages.INVALID_EMAIL);
      setIsLoading(false);
    }
  }

  const inputClass = `form-control ${error && "form-control--error"}`;

  return (
    <LandingPage>
      <Card logo={logo}>
        {isEmailSent ? (
          <ConfirmationEmailSent />
        ) : (
          <>
            <p className="login__title">Reset Password</p>
            <p className="text--light">
              Enter the username associated with your account and we'll send an
              email with instructions to reset your password
            </p>
            <form className="login__form" onSubmit={handleResetPassword}>
              <div className="login__input">
                <input
                  className={inputClass}
                  placeholder="Email Address"
                  type="text"
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  onFocus={() => setError("")}
                  autoFocus
                />
              </div>
              <p className="error">{error}</p>
              <button type="submit" className="btn login__btn">
                {isLoading ? <Loader /> : "Send Instructions"}
              </button>
            </form>
            <Link to="/" className="link text--light">
              Back to Login
            </Link>
          </>
        )}
      </Card>
    </LandingPage>
  );
}

export default ForgotPassword;
