import { useContext } from "react";

import { AppContext } from "../App";
import LandingPage from "../components/LandingPage";
import Card from "../components/Card";
import LoginForm from "../components/LoginForm";
import { checkUser } from "../api/api";
import { USER_LOGIN } from "../constants";

function Login() {
  const { logo, callbackUrl } = useContext(AppContext);

  return (
    <LandingPage>
      <Card withBottom={true} logo={logo}>
        <p className="login__title">
          Login to <span>iSpot.tv</span> and <span>Ace Metrix</span>
        </p>
        <LoginForm
          callbackUrl={callbackUrl}
          checkUsername={checkUser}
          loginType={USER_LOGIN}
        />
      </Card>
    </LandingPage>
  );
}

export default Login;
