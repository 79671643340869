import { useContext } from "react";
import { AppContext } from "../App";
import { ACE_LOCALE } from "../constants";

export default function LandingPage({ children }) {
  const { bgImage, adTitle, locale } = useContext(AppContext);
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.62), rgba(0, 0, 0, 0.62)), url(${bgImage})`,
        backgroundSize: "cover",
      }}
      className="landingPage"
    >
      <div className="landingPage__content">
        {locale === ACE_LOCALE ? (
          <div className="landingPage__text">
            <h1>Welcome to the new Ace Metrix login experience</h1>
            <p>
              As part of our integration with iSpot, we now have a single point
              of sign-on to access both your Ace Metrix and iSpot data.
            </p>
          </div>
        ) : (
          <div className="landingPage__text">
            <h1>The New Standard for TV Ad Measurement</h1>
            <p>
              Justify and optimize tv advertising investments. Fast, accurate,
              actionable cross-screen measurement and attribution you can trust.
            </p>
          </div>
        )}
        <div>{children}</div>
      </div>
      <div className="landingPage__adTitle">{adTitle}</div>
    </div>
  );
}
