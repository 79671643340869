import { useState } from "react";
import Button from "@material-ui/core/Button";

import CustomInput from "./CustomInput";
import { useDomainMutation } from "../hooks/domain";
import { isValidDomain } from "../helpers";
import { errorMessages } from "../constants";

export default function AddDomainForm({ sso_id }) {
  const [domain, setDomain] = useState("");
  const [client, setClient] = useState("");
  const [error, setError] = useState("");

  const { addDomain } = useDomainMutation(sso_id, (err) =>
    setError(err.message)
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValidDomain(domain)) {
      setError(errorMessages.INVALID_DOMAIN);
    } else {
      setError("");
      addDomain({ sso_id, domain, forward_for: client });
      setDomain("");
      setClient("");
    }
  };

  return (
    <form className="admin__form" onSubmit={handleSubmit}>
      <CustomInput
        id="domain"
        name="domain"
        label="Domain"
        value={domain}
        handleChange={(e) => setDomain(e.target.value)}
        required
      />
      <CustomInput
        id="client"
        name="client"
        label="Client"
        value={client}
        handleChange={(e) => setClient(e.target.value)}
      />

      <div className="admin__form__submit">
        <Button variant="contained" type="submit" fullWidth>
          ADD DOMAIN
        </Button>
      </div>
      {error && <p className="error">{error}</p>}
    </form>
  );
}
