import { useState } from "react";

import AdminBack from "../components/AdminBack";
import ConnectorForm from "../components/ConnectorForm";
import { useCreateConnector } from "../hooks/connector";

const ssoData = {
  sso_type: "",
  name: "",
  identifier: "",
  secret: "",
  connector: "",
  scope: "",
  certificate: "",
  status: "",
};

export default function CreateConnector() {
  const [apiError, setApiError] = useState("");

  const { mutateAsync, isLoading } = useCreateConnector();

  const handleCreate = async (ssoData) => {
    setApiError("");
    try {
      await mutateAsync(ssoData);
    } catch (error) {
      setApiError(error.message);
    }
  };

  return (
    <div className="container">
      <AdminBack />
      <ConnectorForm
        ssoData={ssoData}
        onFormSubmit={handleCreate}
        isLoading={isLoading}
        apiError={apiError}
        setApiError={setApiError}
        submitText="SAVE SSO"
      />
    </div>
  );
}
