import { useQuery, useQueryClient, useMutation } from "react-query";

import * as api from "../api/api";
import { useHistory } from "react-router-dom";

export function useGetConnectors() {
  return useQuery("connectors", api.getConnectors);
}

export function useGetConnector(id) {
  return useQuery(["connector", { id }], api.getConnector);
}

export function useCreateConnector() {
  const history = useHistory();
  const cache = useQueryClient();

  return useMutation(api.createConnector, {
    onSuccess: () => {
      cache.invalidateQueries("connectors");
      history.push("/admin/sso");
    },
  });
}

export function useUpdateConnector(id) {
  const history = useHistory();
  const cache = useQueryClient();

  return useMutation(api.updateConnector, {
    onSuccess: () => {
      cache.invalidateQueries(["connector", { id }]);
      cache.invalidateQueries("connectors");
      history.push("/admin/sso");
    },
  });
}
